import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import reportWebVitals from './reportWebVitals';
import Loading from './components/Loading/Loading';

const App = lazy( () => import('./App') );

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);
root.render(
  <React.StrictMode>
    <Loading/>
    <Suspense fallback={ null }>
      <App/>
    </Suspense>
  </React.StrictMode>
);

// <React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
