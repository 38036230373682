import React from 'react';
import './style.scss';
import { useStore } from 'effector-react';
import { $preloader } from '../../stores/preloader';
import { ReactComponent as Preloader } from '../../assets/images/preloader.svg';

const Loading = () => {
  const show = useStore( $preloader );

  if ( !show ) return <></>;

  return (
    <div className="preloader">
      <Preloader/>
    </div>
  );
};

export default Loading;